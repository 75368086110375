<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>政务大数据</h1>
      <p style="text-indent: 2em">
        政务大数据平台整合省社会经济发展资源，打造集数据采集、数据处理、监测管理、预测预警、应急指挥、可视化平台于一体的大数据平台，以信息化提升数据化管理与服务能力，及时准确掌握社会经济发展情况，做到“用数据说话、用数据管理、用数据决策、用数据创新”，牢牢把握社会经济发展主动权和话语权。
      </p>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <h2 class="title">功能场景</h2>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <!-- <div class="detail" @click="go(item.id)">查看详情</div> -->
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/assets/governmentImg/img1.png"),
      active: 0,
      list: [
        {
          id: 0,
          name: "民生服务",
          img: require("../../assets/assets/governmentImg/img2.png"),
          text: "多渠道采集食品药品安全、医疗卫生、社会保障、教育文化、旅游休闲、就业等方面的数据，推动数据的整合、共享和开放,搭载市民个人网页、便民移动应用等，提供全方位的一站式便民信息服务，推进均等化和人性化的民生服务。",
        },
        {
          id: 1,
          name: "城市综合管理",
          img: require("../../assets/assets/governmentImg/img3.png"),
          text: "以城市综合管理为对象，构建科学的预警监控体系，整合城市规划、交通、治安、城管、环境、气象等方面的数据资源，建立可视化和智能化的大数据应用分析模型，通过对海量数据的智能分类、整理和分析，使城市管理者能够更准确地预测可能出现的情况，及时调度资源。",
        },
        {
          id: 2,
          name: "审批服务",
          img: require("../../assets/assets/governmentImg/img4.png"),
          text: "整合各类服务软件的功能,涵盖全部业务,通过流程再造,打破信息孤岛,在互联网、多终端实现了‘一个平台内办两家事、业务办理全程电子化无纸化’”。",
        },
        {
          id: 3,
          name: "大数据分析",
          img: require("../../assets/assets/governmentImg/img5.png"),
          text: "基于数据源提供的数据，利用数据模型可视化分析后台，从不同维度对各种数据指标进行综合分析、分类对比，并对数据进行筛选、条数限制、数据公式计算等操作，生成不同的度量数据模型。",
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/cityDetail",
        query: {
          cityId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/governmentImg/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 156px;
      background: url("../../assets/assets/governmentImg/img1.png") no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
    .classification {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      text-align: center;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
